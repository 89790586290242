<form [formGroup]="form">
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-12 text-center">
                <div class="text-center mb-3">
                    <img height="auto" width="100%" src="{{f.cover_url.value ? f.cover_url.value :'/assets/img/no-image.png'}}" alt="Cover">
                    <div>
                        <div *ngIf="fileCover"><small>Dimensione: {{fileCover?.size / 1024 / 1024 | number : '1.2-2'}} MB</small></div>
                        <small>L'immagine non deve superare 1MB</small><br>
                        <small>Proporzione consigliata 15:6 (es. 1024*410)</small>
                    </div>
                </div>

                <div>
                    <input #img_cover hidden="true" type="file" onclick="this.value=null" (change)="handleFileInputCover($event.target.files)" accept=".jpg" />
                    <button type="button" class="btn btn-info" (click)="img_cover.click()">Scegli immagine</button>
                </div>

                <div *ngIf="f.cover_url.value">
                    <button type="button" class="btn btn-danger mt-2" (click)="removeCover()">Elimina</button>
                </div>
            </div>

            <div class="form-group col-12">
                <label>Nome</label>
                <input formControlName="name" type="text" class="form-control" placeholder="Inserisci un nome" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                <div *ngIf="f.name.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
            </div>

            <div class="form-group col-12">
                <label>Lingue</label>
                <div class="select2-info">
                    <select id="languages" formControlName="languages" class="form-control" data-dropdown-css-class="select2-info"
                        data-placeholder="Scegli una o più lingue" multiple style="width: 100%;" [ngClass]="{ 'is-invalid': submitted && f.languages.errors }">
                    </select>
                    <div *ngIf="f.languages.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                </div>
            </div>

            <div class="form-group col-12">
                <label>Tipologia</label>
                <select formControlName="type_id" (change)="typeChange()" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.type_id.errors }">
                    <option [ngValue]="null" disabled>Scegli una Tipologia</option>
                    <option *ngFor="let d of types; let i = index;" [ngValue]="d.id">{{d.title}}</option>
                </select>
                <div *ngIf="f.type_id.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
            </div>

            <div *ngIf="f.type_id.value == 0" class="form-group col-12">
                <label>Link</label>
                <input [attr.disabled]="f.event_id.value" formControlName="external_link" type="text" class="form-control" placeholder="Inserisci un link"
                    [ngClass]="{ 'is-invalid': submitted && f.external_link.errors }" />
                <div *ngIf="f.external_link.errors?.pattern" class="invalid-feedback">Link non valido</div>
            </div>

            <div *ngIf="f.type_id.value == 1" class="form-group col-12">
                <label>Collega un evento</label>
                <select formControlName="event_id" class="form-control" [attr.disabled]="f.external_link.value ? 'disabled' : null">
                    <option [ngValue]="null">Nessuno</option>
                    <option *ngFor="let p of events; let i = index;" [ngValue]="p.event_id">
                        {{p.event_id}} - {{p.event_date * 1000 | date :'dd/MM/yyyy HH:mm'}} - {{p.place_name}}
                    </option>
                </select>
            </div>

            <div *ngIf="f.type_id.value == 2 || f.type_id.value == 3" class="form-group col-12">
                <label>Collega una città</label>
                <select formControlName="city_id" (change)="cityChange()" class="form-control">
                    <option [ngValue]="null">Nessuno</option>
                    <option *ngFor="let p of cities; let i = index;" [ngValue]="p.city_id">{{p.city_name}}</option>
                </select>
            </div>

            <div *ngIf="f.type_id.value == 3" class="form-group col-12">
                <label>Collega un area</label>
                <select formControlName="area_id" class="form-control">
                    <option [ngValue]="null">Nessuno</option>
                    <option *ngFor="let p of areas; let i = index;" [ngValue]="p.area_id">{{p.name}}</option>
                </select>
            </div>

        </div>
    </div>

    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" (click)="dismiss()">Chiudi</button>
        <button type="submit" class="btn btn-info" [disabled]="loading" (click)="save()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Salva</button>
    </div>
</form>