export class City {
    city_id: number;
    city_name: string;
    country_id?: number;
    macro_area?: number;
    country_name?: string;
    image_url?: string;
    email_logo_url?: string;
    email_logo_hex_color?: string;
    email_header_text_hex_color?: string;
    app_logo_url?: string;
    geolocation_required: number;
    booking_geolocation_required: number;
    enabled: number;
    emission_url?: string;
    instant_booking_enabled: number;

    talent_month_events_limit: number;
    talent_slots_visibility_type: number;
    talent_slots_visibility_value: number;
    profession_month_events_limit: number;
    profession_slots_visibility_type: number;
    profession_slots_visibility_value: number;

    professions: CityProfession[];

    constructor(city_id: number | null, city_name: string) {
        this.city_id = city_id;
        this.city_name = city_name;
    }

    public static getIds(cities: City[]) {
        var ids = [];
        for (let city of cities) {
            ids.push(city.city_id);
        }
        return ids;
    }
}

export class CityPolicy {
    policy_id: number;
    name: string;
    url: string;
}

export class CityArea {
    area_id: number;
    name: string;
    hex_color?: string;
    events_per_day: number;
}

export class CitySlot {
    slot_id: number;
    start_time: string;
    end_time: string;
    status: number = 1; //default
    whole_day: number;
}

export class CityProfession {
    city_id: number;
    profession_id: number;
    type: number;
    is_group: number;
    name: string;
}