import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { City } from 'src/app/models/city';
import { RestService } from 'src/app/utils/services/rest.service';

@Component({
  selector: 'app-modal-filter-roster',
  templateUrl: './modal-filter-roster.component.html',
  styleUrls: ['./modal-filter-roster.component.css']
})
export class ModalFilterRosterComponent implements OnInit {
  @Input() cities: City[];
  @Input() stars: number[];

  form: FormGroup;

  starItems = [
    { id: 0, name: "0 stelle" },
    { id: 1, name: "1 stella" },
    { id: 2, name: "2 stelle" },
    { id: 3, name: "3 stelle/Roaster" }
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private rest: RestService
  ) { }

  ngOnInit(): void {

    this.form = new FormGroup({
      cities: new FormControl(this.cities),
      stars: new FormControl(this.stars),
    });

    this.rest.citiesList().subscribe(res => {
      this.cities = res.data.cities;
    }, error => {
    });
  }

  get f() {
    return this.form.controls;
  }

  public onSelectAll() {
    const selected = this.cities.map(item => item.city_id);
    this.f.cities.patchValue(selected);
  }

  public onClearAll() {
    this.f.cities.patchValue([]);
  }

  apply() {
    this.activeModal.close({ cities: this.f.cities.value, stars: this.f.stars.value })
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
