import { Component, OnInit, ViewChild, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user';
import { DataTableDirective } from 'angular-datatables';
import { City, CityArea, CityPolicy, CityProfession, CitySlot } from 'src/app/models/city';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { ModalPlaceDatesComponent } from 'src/app/modals/modal-place-dates/modal-place-dates.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
import { ModalPlaceSlotAddComponent } from 'src/app/modals/modal-place-slot-add/modal-place-slot-add.component';
import { Profession } from 'src/app/models/profession';
import { log } from 'console';
declare var $: any;

@Component({
  selector: 'app-city-detail',
  templateUrl: './city-detail.component.html',
  styleUrls: ['./city-detail.component.css']
})
export class CityDetailComponent implements OnDestroy, OnInit {
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;

  id: number;
  city: City;
  areas: CityArea[] = [];
  policies: CityPolicy[] = [];
  slots: CitySlot[] = [];

  loading = false;
  isLoading: boolean = false;
  isLoadingAreas: boolean = false;
  isLoadingPolicies: boolean = false;
  isLoadingSlots: boolean = false;

  submitted = false;
  isUpdate = false;

  form: FormGroup;
  formArea: FormGroup;
  formPolicy: FormGroup;

  dtOptionsPolicies: DataTables.Settings = {};
  dtTriggerPolicies: Subject<any> = new Subject();

  dtOptionsAreas: DataTables.Settings = {};
  dtTriggerAreas: Subject<any> = new Subject();

  fileImage: File = null;
  deleteImage: Boolean = false;
  selectedOrg: User;

  fileAppLogo: File = null;
  deleteAppLogo: Boolean = false;

  fileEmailLogo: File = null;
  deleteEmailLogo: Boolean = false;

  professions: Profession[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rest: RestService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public auth: AuthenticationService
  ) {
    this.id = this.route.snapshot.params['id'];
  }

  get selectedOrgMod() {
    return this.selectedOrg;
  }
  set selectedOrgMod(value) {
    this.selectedOrg = value;
  }

  ngOnInit(): void {

    this.dtOptionsAreas = {
      columnDefs: [{ orderable: false, targets: [1, 2] }],
      order: [0, "asc"],
      language: { url: "./assets/datatable_ITA.json" },
      destroy: true
    };

    this.dtOptionsPolicies = {
      columnDefs: [{ orderable: false, targets: [2] }],
      order: [0, "asc"],
      language: { url: "./assets/datatable_ITA.json" },
      destroy: true
    };

    this.form = new FormGroup({
      city_name: new FormControl(null, Validators.required),
      image_url: new FormControl(null),
      email_logo_url: new FormControl(null),
      email_logo_hex_color: new FormControl(null, CustomValidators.exadecimalColor()),
      email_header_text_hex_color: new FormControl(null, CustomValidators.exadecimalColor()),
      app_logo_url: new FormControl(null),
      country_id: new FormControl(1, Validators.required),
      macro_area: new FormControl(null, Validators.required),
      geolocation_required: new FormControl(0, Validators.required),
      booking_geolocation_required: new FormControl(0, Validators.required),
      enabled: new FormControl(true),
      emission_url: new FormControl(null),
      talent_month_events_limit: new FormControl(null, Validators.required),
      talent_slots_visibility_type: new FormControl(0, Validators.required),
      talent_slots_visibility_value: new FormControl(null, Validators.required),
      profession_month_events_limit: new FormControl(null, Validators.required),
      profession_slots_visibility_type: new FormControl(0, Validators.required),
      profession_slots_visibility_value: new FormControl(null, Validators.required),
      instant_booking_enabled: new FormControl(0),
      espressioni: new FormControl(),
      espressioni_group: new FormControl(),
      mestieri: new FormControl(),
      mestieri_group: new FormControl()
    });

    this.formArea = new FormGroup({
      area_id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      hex_color: new FormControl(null, CustomValidators.exadecimalColor()),
      events_per_day: new FormControl(4, Validators.required),
      premium: new FormControl(0, Validators.required)
    })

    this.formPolicy = new FormGroup({
      policy_id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      url: new FormControl(null, Validators.required)
    })

    this.professionsList();

    if (this.id) {
      this.cityDetail(this.id);
      this.slotsList();
    }

  }

  ngOnDestroy(): void {
    this.dtTriggerAreas.unsubscribe();
    this.dtTriggerPolicies.unsubscribe();
  }

  /** Forms */

  get f() {
    return this.form.controls;
  }
  get fa() {
    return this.formArea.controls;
  }
  get fp() {
    return this.formPolicy.controls;
  }

  /** API */

  cityDetail(id) {
    this.loading = true;
    this.isLoading = true;
    this.isLoadingAreas = true;
    this.isLoadingPolicies = true;

    this.rest.cityDetail(id).subscribe((result) => {
      this.loading = false;
      this.isLoading = false;
      this.isLoadingAreas = false;
      this.isLoadingPolicies = false;

      this.city = result.data.city;
      this.areas = result.data.areas;
      this.policies = result.data.policies;
console.log(this.city);

      this.form.setValue({
        city_name: this.city.city_name,
        image_url: this.city.image_url,
        email_logo_url: this.city.email_logo_url,
        email_logo_hex_color: this.city.email_logo_hex_color,
        email_header_text_hex_color: this.city.email_header_text_hex_color,
        app_logo_url: this.city.app_logo_url,
        country_id: this.city.country_id,
        macro_area: this.city.macro_area,
        geolocation_required: this.city.geolocation_required,
        booking_geolocation_required: this.city.booking_geolocation_required,
        enabled: this.city.enabled == 1,
        emission_url: this.city.emission_url ? this.city.emission_url : "",
        talent_month_events_limit: this.city.talent_month_events_limit,
        talent_slots_visibility_type: this.city.talent_slots_visibility_type,
        talent_slots_visibility_value: this.city.talent_slots_visibility_value,
        profession_month_events_limit: this.city.profession_month_events_limit,
        profession_slots_visibility_type: this.city.profession_slots_visibility_type,
        profession_slots_visibility_value: this.city.profession_slots_visibility_value,
        instant_booking_enabled: this.city.instant_booking_enabled,
        espressioni: this.city.professions.filter(a => a.type == 1 && a.is_group == 0).map(item => (item.profession_id)),
        espressioni_group: this.city.professions.filter(a => a.type == 1 && a.is_group == 1).map(item => (item.profession_id)),
        mestieri: this.city.professions.filter(a => a.type == 2 && a.is_group == 0).map(item => (item.profession_id)),
        mestieri_group: this.city.professions.filter(a => a.type == 2 && a.is_group == 1).map(item => (item.profession_id)),
      });

      if (this.isUpdate) {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            dtInstance.destroy();
            this.dtTriggerAreas.next();
            this.dtTriggerPolicies.next();
          });
        });
      } else {
        this.isUpdate = true;
        this.dtTriggerAreas.next();
        this.dtTriggerPolicies.next();
      }

    }, error => {
      this.loading = false;
      this.isLoading = false;
      this.isLoadingAreas = false;
      this.isLoadingPolicies = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /** PROFESSIONI */
  professionsList() {
    this.rest.professionsAllList().subscribe((result) => {
      this.professions = result.data.professions;
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /** SLOTS */

  slotsList() {
    this.isLoadingSlots = true;

    this.rest.citySlotsList(this.id).subscribe((result) => {
      this.isLoadingSlots = false;
      this.slots = result.data.slots;
    }, error => {
      this.isLoadingSlots = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  addSlot() {
    const modalRef = this.modalService.open(ModalPlaceSlotAddComponent);
    modalRef.result.then((result: CitySlot) => {
      this.isLoadingSlots = true;

      this.rest.citySlotAdd(this.id, result.start_time, result.end_time, result.whole_day).subscribe(res => {
        this.isLoadingSlots = false;
        this.slotsList();
      }, error => {
        this.isLoadingSlots = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }, (reason) => { });
  }

  deleteSlot(slot_id: number) {
    this.isLoadingSlots = true;

    this.rest.citySlotDelete(slot_id).subscribe(res => {
      this.isLoadingSlots = false;
      this.slotsList();
    }, error => {
      this.isLoadingSlots = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /** Image */
  handleFileInput(files: FileList, type: number) {
    if (!files || files.length <= 0) {
      return;
    }

    var reader = new FileReader();

    switch (type) {
      case 0: // immagine copertina
        this.fileImage = files[0];
        reader.onload = (event) => {
          this.f.image_url.setValue(event.target.result);
        };
        reader.readAsDataURL(this.fileImage);
        break;
      case 1: // logo email
        this.fileEmailLogo = files[0];
        reader.onload = (event) => {
          this.f.email_logo_url.setValue(event.target.result);
        };
        reader.readAsDataURL(this.fileEmailLogo);
        break;
      case 2: // logo app
        this.fileAppLogo = files[0];
        reader.onload = (event) => {
          this.f.app_logo_url.setValue(event.target.result);
        };
        reader.readAsDataURL(this.fileAppLogo);
        break;
    }

  }

  removeImage(type: number) {
    switch (type) {
      case 0: // immagine copertina
        this.fileImage = null;
        this.f.image_url.setValue(null);
        this.deleteImage = true;
        break;
      case 1: // logo email
        this.fileEmailLogo = null;
        this.f.email_logo_url.setValue(null);
        this.deleteEmailLogo = true;
        break;
      case 2: // logo app
        this.fileAppLogo = null;
        this.f.app_logo_url.setValue(null);
        this.deleteAppLogo = true;
        break;
    }
  }

  /** Policy Modal */

  showAddPolicyModal() {
    this.formPolicy.reset();

    $("#modal-policy").modal('show');
  }


  /** Area Modal */

  showAddAreaModal() {
    this.formArea.reset();

    $("#modal-area").modal('show');
  }

  showEditAreaModal(area) {
    this.formArea.reset();

    this.formArea.setValue({
      area_id: area.area_id,
      name: area.name,
      hex_color: area.hex_color,
      events_per_day: area.events_per_day,
      premium: area.premium
    });

    $("#modal-area").modal('show');
  }


  /** Area */

  saveArea() {
    this.submitted = true;

    if (this.formArea.invalid) {
      return;
    }

    this.submitted = false;

    $("#modal-area").modal('hide');

    this.isLoading = true;

    if (this.fa.area_id.value != null) {
      this.rest.cityAreaEdit(this.fa.area_id.value, this.fa.name.value, this.fa.hex_color.value.toString(), this.fa.events_per_day.value, this.fa.premium.value).subscribe(res => {
        this.isLoading = false;
        this.formArea.reset();
        this.cityDetail(this.id);
      }, error => {
        this.isLoading = false;
        this.formArea.reset();
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.rest.cityAreaAdd(this.id, this.fa.name.value, this.fa.hex_color.value.toString(), this.fa.events_per_day.value, this.fa.premium.value).subscribe(res => {
        this.isLoading = false;
        this.formArea.reset();
        this.cityDetail(this.id);
      }, error => {
        this.isLoading = false;
        this.formArea.reset();
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }
  }

  deleteArea(area_id) {
    this.isLoading = true;

    this.rest.cityAreaDelete(area_id).subscribe(res => {
      this.isLoading = false;
      this.cityDetail(this.id);
    }, error => {
      this.isLoading = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }



  /** Policy */

  savePolicy() {
    this.submitted = true;

    if (this.formPolicy.invalid) {
      return;
    }

    this.submitted = false;

    $("#modal-policy").modal('hide');

    this.isLoading = true;

    this.rest.cityPolicyAdd(this.id, this.fp.name.value, this.fp.url.value.toString()).subscribe(res => {
      this.isLoading = false;
      this.formPolicy.reset();
      this.cityDetail(this.id);
    }, error => {
      this.isLoading = false;
      this.formPolicy.reset();
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  deletePolicy(policy_id) {
    this.isLoading = true;

    this.rest.cityPolicyDelete(policy_id).subscribe(res => {
      this.isLoading = false;
      this.cityDetail(this.id);
    }, error => {
      this.isLoading = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }


  /** Info */

  save() {
    this.submitted = true;

    if (this.form.invalid) { return; }

    this.submitted = false;

    if (this.fileImage?.size / 1024 / 1024 > 2) {
      this.toastr.error('Immagine di copertina troppo grande!');
      return;
    }

    if (this.fileAppLogo?.size / 1024 / 1024 > 2 || this.fileEmailLogo?.size / 1024 / 1024 > 2) {
      this.toastr.error('Logo troppo grande!');
      return;
    }

    const formData = new FormData();
    formData.append('city_name', this.f.city_name.value.toString());
    formData.append('country_id', this.f.country_id.value);
    formData.append('macro_area', this.f.macro_area.value);
    formData.append('geolocation_required', this.f.geolocation_required.value);
    formData.append('booking_geolocation_required', this.f.booking_geolocation_required.value);
    formData.append('enabled', this.f.enabled.value ? "1" : "0");
    formData.append('emission_url', this.f.emission_url.value);
    formData.append('instant_booking_enabled', this.f.instant_booking_enabled.value);

    formData.append('talent_month_events_limit', this.f.talent_month_events_limit.value);
    formData.append('talent_slots_visibility_type', this.f.talent_slots_visibility_type.value);
    formData.append('talent_slots_visibility_value', this.f.talent_slots_visibility_value.value);
    formData.append('profession_month_events_limit', this.f.profession_month_events_limit.value);
    formData.append('profession_slots_visibility_type', this.f.profession_slots_visibility_type.value);
    formData.append('profession_slots_visibility_value', this.f.profession_slots_visibility_value.value);

    formData.append("delete_image", this.deleteImage ? '1' : '0');
    formData.append("delete_email_logo", this.deleteEmailLogo ? '1' : '0');
    formData.append("delete_app_logo", this.deleteAppLogo ? '1' : '0');

    if (this.fileImage) formData.append("file", this.fileImage);
    if (this.fileEmailLogo) formData.append("email_logo", this.fileEmailLogo);
    if (this.fileAppLogo) formData.append("app_logo", this.fileAppLogo);

    if (this.f.email_logo_hex_color.value) formData.append("email_logo_hex_color", this.f.email_logo_hex_color.value.toString());
    if (this.f.email_header_text_hex_color.value) formData.append("email_header_text_hex_color", this.f.email_header_text_hex_color.value.toString());


    var professions = [];
    var espressioni = this.f.espressioni.value;
    var espressioni_group = this.f.espressioni_group.value;
    var mestieri = this.f.mestieri.value;
    var mestieri_group = this.f.mestieri_group.value;

    professions = professions
      .concat(espressioni.map(a => ({ "profession_id": a, "type": 1, "is_group": 0 })))
      .concat(espressioni_group.map(a => ({ "profession_id": a, "type": 1, "is_group": 1 })))
      .concat(mestieri.map(a => ({ "profession_id": a, "type": 2, "is_group": 0 })))
      .concat(mestieri_group.map(a => ({ "profession_id": a, "type": 2, "is_group": 1 })));
    formData.append("professions", JSON.stringify(professions));
    
    this.loading = true;
    this.isLoading = true;

    if (this.id) {
      formData.append('city_id', this.id.toString());

      this.rest.cityEdit(formData).subscribe(res => {
        this.loading = false;
        this.isLoading = false;
        this.toastr.success('Città aggiornata con successo!');
        this.cityDetail(this.id);
      }, error => {
        this.loading = false;
        this.isLoading = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.rest.cityAdd(formData).subscribe(res => {
        this.loading = false;
        this.isLoading = false;
        this.toastr.success('Città creata con successo!');
        this.router.navigate(['/cities']);
      }, error => {
        this.loading = false;
        this.isLoading = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }
  }


  /** Date */

  openModalAddDate() {
    const modalRef = this.modalService.open(ModalPlaceDatesComponent);

    modalRef.componentInstance.cityID = this.id;
    modalRef.componentInstance.editMode = false;
    modalRef.componentInstance.removeMode = false;
    modalRef.componentInstance.showPlaces = true;

    modalRef.result.then((result) => {
      this.cityDetail(this.id);
    }, (reason) => { });
  }
}
