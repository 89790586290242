<section class="content-header">
    <div class="container-fluid">
        <h1>Awards</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <button class="btn btn-info mb-3" (click)="edit(null)"><i class="fas fa-plus mr-1"></i>
                    Aggiungi</button>

                <div class="card">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Punti</th>
                                    <th>Nome</th>
                                    <th style="width: 20%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of awards">
                                    <td>{{item.points}}</td>
                                    <td>{{item.title}}</td>
                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm" (click)="edit(item)"><i class="fas fa-eye mr-1"></i> Visualizza</button>
                                        <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo award ?" icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }" (confirm)="delete(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal add/edit -->
<div class="modal fade" id="modal-default" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <form [formGroup]="form">
                <div class="modal-body">
                    <input formControlName="award_id" type="hidden">

                    <div class="form-group col-12 text-center">
                        <div class="text-center mb-3">
                            <img class="image" height="150" width="150" src="{{f.image_url.value ? f.image_url.value :'/assets/img/no-image.png'}}" alt="Cover">
                            <div>
                                <div *ngIf="fileCover"><small>Dimensione: {{fileCover?.size / 1024 / 1024 | number : '1.2-2'}} MB</small></div>
                                <small>L'immagine non deve superare 1MB</small><br>
                            </div>
                        </div>

                        <div>
                            <input #img_cover hidden="true" type="file" onclick="this.value=null" (change)="handleFileInputCover($event.target.files)" accept=".jpg, .png" />
                            <button type="button" class="btn btn-info" (click)="img_cover.click()">Scegli immagine</button>
                        </div>
                    </div>
 
                    <div class="row mb-3">
                        <div class="col-6">
                            <label>Nome</label>
                            <input formControlName="title" type="text" class="form-control" placeholder="Inserisci il nome dell'award" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
                            <div *ngIf="f.title.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        </div>

                        <div class="col-6">
                            <label>Numero punti</label>
                            <input formControlName="points" type="number" class="form-control" placeholder="Inserisci il numero di punti" [ngClass]="{ 'is-invalid': submitted && f.points.errors }" />
                            <div *ngIf="f.points.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                            <div *ngIf="f.points.errors?.min" class="invalid-feedback">Inserisci un valore > 0</div>
                            <div *ngIf="f.points.errors?.pattern" class="invalid-feedback">Numero non valido</div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label>Email da inviare allo User al raggiungimento del punteggio</label>
                        <textarea id="email_user" formControlName="email_user" [ngClass]="{ 'is-invalid': submitted && f.email_user.errors }"></textarea>
                        <div *ngIf="f.email_user.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                    </div>

                    <div>
                        <label>Email da inviare al Talent al raggiungimento del punteggio</label>
                        <textarea id="email_talent" formControlName="email_talent" [ngClass]="{ 'is-invalid': submitted && f.email_talent.errors }"></textarea>
                        <div *ngIf="f.email_talent.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                    <button type="submit" class="btn btn-info" (click)="save()">Salva</button>
                </div>
            </form>

        </div>
    </div>
</div>