import { CitySlot } from "./city";

export class Place {
    place_id: number;
    place_name: string;
    city_id: number;
    area_id?: number;
    area_name: string;
    address: string;
    type: number;
    peripheral_id: string;
    description: string;
    description_en?: string;
    description_pt?: string;
    description_es?: string;
    dates: PlaceDate[];
    gmove_locationid: string;
    wiseair_url: string;
    lat: number;
    lon: number;
    timer_tv_10: number;
    timer_tv_55: number;
    cover_url: string;
    tint_color: string;
    days_between_user_events: number;
    emission: number;
    profession: number;
    talent: number;
    require_approval: number;
    road_coefficient: number;
    professions?: [number];
    enabled: number;
    places_connections?: [number];
    accept_email_extra?: string;
    talent_month_events_limit: number;
    talent_day_events_limit: number;
    pdf_url?: string;
    geoportal_url?: string;
    locker_code?: string;
    serial_number?: string;
    highlighted_text?: string;

    public static getIds(places: Place[]) {
        var ids = [];
        for (let place of places) {
            ids.push(place.place_id);
        }
        return ids;
    }
}

export class PlaceDate {
    date: string;
    slots: CitySlot[] = [];
}